import { useModel, useIntl } from 'umi';


import ReactPWAInstallProvider, { useReactPWAInstall } from "react-pwa-install";


import { Link } from "react-router-dom";
import { Row, Col, Divider, Button, Space } from 'antd';

import SocketConnector from '../SocketConnector';
import SessionCountdown from '@/components/SessionCountdown';
import Cumcierge from '@/components/Cumcierge';
import PWAInstaller from '@/components/PWAInstaller';
import AppTabBar from './components/AppTabBar';
import StatusMessengerList from '@/components/StatusMessenger/components/List';
import StatusMessengerButton from '@/components/StatusMessenger/components/Button'

import LiveCam from '../LiveCam';

import ReactGA from "react-ga4";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AllDTIcons from '@fortawesome/pro-duotone-svg-icons';
import * as AllBrandIcons from '@fortawesome/free-brands-svg-icons';

import { useMediaQuery } from 'react-responsive'




import './assets/css/index.css';



export default () => {


  
  const intl = useIntl();

  const { initialState } = useModel('@@initialState');

  const isMobile = useMediaQuery({ query: '(max-width: 767.98px)' })


  const onPhoneClick = () => {
    ReactGA.event({
      category: "phone",
      action: "phone_click",
      //label: "credits confirm", // optional
      //value: 99, // optional, must be a number
      //nonInteraction: true, // optional, true/false
      //transport: "xhr", // optional, beacon/xhr/image
    });
  }

  return (
    <>



      

     


      <StatusMessengerList />

      <footer className="page-footer">
        {isMobile &&
          <AppTabBar />
        }




        <div className="inner">
          <Row gutter={24}>
            <Col lg={6} md={12} xs={24}>

              <Link to={'/'}><img className="logo" alt="escort.ch" src="/images/mailLogo.png" width="200" height="28" /></Link><br />
              <Space className="social-bar">

                <a href={'https://www.x.com/swiss_escort'} target="_blank" title="X">
                  <Button size="large" type="text" title="X">
                    <FontAwesomeIcon className="mr-2" icon={AllBrandIcons.faXTwitter} />
                  </Button>
                </a>
                <a href={'https://t.me/+A7BrmnYQ6fJlMWM0'} target="_blank" title="Telegram">
                  <Button size="large" type="text" title="Telegram">
                    <FontAwesomeIcon className="mr-2" icon={AllBrandIcons.faTelegram} />
                  </Button>
                </a>

                <ReactPWAInstallProvider>
      <PWAInstaller />
      </ReactPWAInstallProvider>

              </Space>
            </Col>
            <Col md={0} sm={24} xs={24}>
              <Divider />
            </Col>
            <Col lg={4} md={6} xs={12}>
              <ul className="center-menu">
                <li>
                  <Link to={'/prices/profile'} title={intl.formatMessage({ id: 'footer.link.profile', defaultMessage: 'Profil Preise' })}>
                    {intl.formatMessage({ id: 'footer.link.profile', defaultMessage: 'Profil Preise' })}
                  </Link>
                </li>
                <li>
                  <Link to={'/prices/banner'} title={intl.formatMessage({ id: 'footer.link.banner', defaultMessage: 'Banner Preise' })}>
                    {intl.formatMessage({ id: 'footer.link.banner', defaultMessage: 'Banner Preise' })}
                  </Link>
                </li>
                <li>
                  <Link to={'/support'} title={intl.formatMessage({ id: 'footer.link.support', defaultMessage: 'Support' })}>
                    {intl.formatMessage({ id: 'footer.link.support', defaultMessage: 'Support' })}
                  </Link>
                </li>
                {/**<li>
                  <Link to={'/partner'} title={intl.formatMessage({ id: 'footer.link.partner', defaultMessage: 'Partner' })}>
                    {intl.formatMessage({ id: 'footer.link.partner', defaultMessage: 'Partner' })}
                  </Link>
                </li> */}
              </ul>
            </Col>
            <Col lg={4} md={6} xs={12}>
              <ul className="center-menu">
                <li>
                  <Link to={'/blog'} title={intl.formatMessage({ id: 'footer.link.blog', defaultMessage: 'Blog' })}>
                    {intl.formatMessage({ id: 'footer.link.blog', defaultMessage: 'Blog' })}
                  </Link>
                </li>
                <li>
                  <Link to={'/faq'} title={intl.formatMessage({ id: 'footer.link.faq', defaultMessage: 'FAQ' })}>
                    {intl.formatMessage({ id: 'footer.link.faq', defaultMessage: 'FAQ' })}
                  </Link>
                </li>
                <li>
                  {!initialState.user &&
                    <Link to={'/user/login'} title={intl.formatMessage({ id: 'footer.link.login', defaultMessage: 'Login' })}>
                      {intl.formatMessage({ id: 'footer.link.login', defaultMessage: 'Login' })}
                    </Link>
                  }

                  {initialState.user &&
                    <Link to={'/user/profile'} title={intl.formatMessage({ id: 'rightcontent.tooltip.profile', defaultMessage: 'Profile' })}>
                      {intl.formatMessage({ id: 'rightcontent.tooltip.profile', defaultMessage: 'Profile' })}
                    </Link>
                  }


                </li>
             

              </ul>
            </Col>
            <Col lg={0} md={24} xs={24}>
              <Divider />
            </Col>
            <Col lg={10} md={24} xs={24}>
              <div className="help-bar">
                <Row gutter={24}>
                  <Col md={12} xs={24}>
                    <Button title="Support" onClick={onPhoneClick} href={'tel:+41799330044'} type="primary" className="btn-purple btn-phone"> <FontAwesomeIcon className="mr-2" icon={AllDTIcons.faPhone} /> {'+41 79 933 00 44'}</Button>
                  </Col>
                  <Col md={12} xs={24}>
                    <div className="opening">
                      {intl.formatMessage({ id: 'footer.opening.row1', defaultMessage: 'Mo-Fr 09:00 bis 21:00' })}<br />
                      {intl.formatMessage({ id: 'footer.opening.row2', defaultMessage: 'Sa/So 10:00 bis 15:00' })}<br /><br />
                    </div>
                  </Col>
                </Row>
                <p>
                  {intl.formatMessage({ id: 'footer.opening.text', defaultMessage: 'WhatsApp und E-Mails werden um 22:00 Uhr nochmals bearbeitet.' })}
                </p>

              </div>
            </Col>
          </Row>
          <Divider />
          <div className="bottom-bar">
            <span className="copyright">
              {'Copyright © escort.ch'}
            </span>
            <span className="legal-menu">
              <ul>
                <li>
                  <Link to={'/legal/compliance'} title={intl.formatMessage({ id: 'footer.legal.compliance', defaultMessage: 'Hinweis zur Einhaltung' })}>
                    {intl.formatMessage({ id: 'footer.legal.compliance', defaultMessage: 'Hinweis zur Einhaltung' })}
                  </Link>
                </li>
                <li>
                  <Link to={'/legal/refund'} title={intl.formatMessage({ id: 'footer.legal.refund', defaultMessage: 'Rückgaberecht' })}>
                    {intl.formatMessage({ id: 'footer.legal.refund', defaultMessage: 'Rückgaberecht' })}
                  </Link>
                </li>
                <li>
                  <Link to={'/legal/gtc'} title={intl.formatMessage({ id: 'footer.legal.gtc', defaultMessage: 'AGB' })}>
                    {intl.formatMessage({ id: 'footer.legal.gtc', defaultMessage: 'AGB' })}
                  </Link>
                </li>
                <li>
                  <Link to={'/legal/privacy'} title={intl.formatMessage({ id: 'footer.legal.privacy', defaultMessage: 'Datenschutz' })}>
                    {intl.formatMessage({ id: 'footer.legal.privacy', defaultMessage: 'Datenschutz' })}
                  </Link>
                </li>
                <li>
                  <Link to={'/legal/imprint'} title={intl.formatMessage({ id: 'footer.legal.imprint', defaultMessage: 'Impressum' })}>
                    {intl.formatMessage({ id: 'footer.legal.imprint', defaultMessage: 'Impressum' })}
                  </Link>
                </li>
              </ul>
            </span>

          </div>
        </div >
      </footer >

      <SocketConnector />

      <SessionCountdown />


     {/** <Cumcierge /> <LiveCam /> */}

      {
        !isMobile &&
        <StatusMessengerButton showFloatingButton />
      }

    </>
  );
};
